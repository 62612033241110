export default [
  // {
  //   path: "/home",
  //   name: "home",
  //   component: () => import("@/views/admin/dashboard/dashboard.vue"),
  // },
  {
    path: "/home/:module",
    name: "home-report",
    component: () =>
      import("@/views/admin/dashboard/report-redirect/index.vue"),
  },
  // dashboard-kki
  {
    path: "/dashboard-kki",
    name: "dashboard-kki",
    component: () => import("@/views/admin/dashboardkki/dashboard.vue"),
  },
  {
    path: "/dashboard-kki/:module",
    name: "dashboard-kki-report",
    component: () =>
      import("@/views/admin/dashboardkki/report-redirect/dashboardDetail.vue"),
  },
  // dashboard sla admin
  {
    path: "/dashboard-sla-admin",
    name: "dashboard-sla-admin",
    component: () => import("@/views/admin/dashboardSLA/dashboard.vue"),
  },
  {
    path: "/dashboard-sla/:id",
    name: "dashboard-sla",
    component: () => import("@/views/admin/dashboardSLA/dashboardSLA.vue"),
  },
  {
    path: "/dashboard-sla-alur/:type/:id/:idalur",
    name: "dashboard-sla-alur",
    component: () =>
      import("@/views/admin/dashboardSLA/dashboardPerAlurProses.vue"),
  },
  // daftar pemohon
  {
    path: "/admin/daftar-pemohon",
    name: "admin-daftar-pemohon",
    component: () => import("@/views/admin/pemohon/daftarPemohon.vue"),
  },
  {
    path: "/admin/daftar-pemohon/add",
    name: "admin-daftar-pemohon-add",
    component: () =>
      import("@/views/admin/pemohon/tambahUbahFom/formTambahPermohonan.vue"),
  },
  {
    path: "/admin/daftar-pemohon/ubah",
    name: "admin-daftar-pemohon-ubah",
    component: () =>
      import("@/views/admin/pemohon/tambahUbahFom/formTambahPermohonan.vue"),
  },
  {
    path: "/admin/daftar-pemohon/detail",
    name: "admin-daftar-pemohon/detail",
    component: () =>
      import("@/views/admin/pemohon/detailPemohon/detailPemohon.vue"),
    children: [
      {
        path: "/admin/daftar-pemohon/detail-pengguna",
        name: "admin-daftar-pemohon-detail-pengguna",
        component: () =>
          import(
            "@/views/admin/pemohon/detailPemohon/componen/detailPengguna.vue"
          ),
      },
    ],
  },

  // provinsi
  {
    path: "/provinsi",
    name: "provinsi",
    component: () => import("@/views/admin/provinsi/daftarProvinsi.vue"),
  },
  {
    path: "/provinsi/view/:id",
    name: "provinsi-view",
    component: () => import("@/views/admin/provinsi/detailProvinsi.vue"),
  },

  // kabupaten
  {
    path: "/kabupaten",
    name: "kabupaten",
    component: () => import("@/views/admin/kabupaten/daftarKabupaten.vue"),
  },
  {
    path: "/kabupaten/view/:id",
    name: "kabuaten-view",
    component: () => import("@/views/admin/kabupaten/detailKabupaten.vue"),
  },

  // kecamatan
  {
    path: "/kecamatan",
    name: "kecamatan",
    component: () => import("@/views/admin/kecamatan/daftarKecamatan.vue"),
  },
  {
    path: "/kecamatan/view/:id",
    name: "kecamatan-view",
    component: () => import("@/views/admin/kecamatan/detailKecamatan.vue"),
  },

  // desa
  {
    path: "/desa",
    name: "desa",
    component: () => import("@/views/admin/desa/daftarDesa.vue"),
  },
  {
    path: "/desa/view/:id",
    name: "desa-view",
    component: () => import("@/views/admin/desa/detailDesa.vue"),
  },

  // jenis izin
  {
    path: "/jenis-izin",
    name: "jenis-izin",
    component: () => import("@/views/admin/jenis-izin/daftarPerizinan.vue"),
  },
  {
    path: "/jenis-izin/add",
    name: "jenis-izin-add",
    component: () =>
      import("@/views/admin/jenis-izin/formTambah/formTambahUbahPerizinan.vue"),
  },
  {
    path: "/jenis-izin/edit/:id",
    name: "jenis-izin-edit",
    component: () =>
      import("@/views/admin/jenis-izin/formTambah/formTambahUbahPerizinan.vue"),
  },
  {
    path: "/jenis-izin/view/:id",
    name: "jenis-izin-view",
    component: () => import("@/views/admin/jenis-izin/detailPerizinan.vue"),
  },

  // alur proses
  {
    path: "/alur-proses",
    name: "alur-proses",
    component: () => import("@/views/admin/alurproses/daftarAlurProses.vue"),
  },
  {
    path: "/alur-proses/add",
    name: "alur-proses-add",
    component: () =>
      import("@/views/admin/alurproses/tambahUbahDetailAlurProses.vue"),
  },
  {
    path: "/alur-proses/edit/:id",
    name: "alur-proses-ubah",
    component: () =>
      import("@/views/admin/alurproses/tambahUbahDetailAlurProses.vue"),
  },
  {
    path: "alur-proses/view/:id",
    name: "alur-proses-detail",
    component: () =>
      import("@/views/admin/alurproses/tambahUbahDetailAlurProses.vue"),
  },

  // alter pemohon
  {
    path: "/pemohon/alter",
    name: "alter-pemohon",
    component: () =>
      import("@/views/admin/alterpemohon/daftarAlterPemohon.vue"),
  },
  {
    path: "/pemohon/alter/edit/:id",
    name: "alter-pemohon-edit",
    component: () => import("@/views/admin/alterpemohon/ubahAlterPemohon.vue"),
  },

  // jenis Proses
  {
    path: "/jenis-proses",
    name: "jenis-proses",
    component: () => import("@/views/admin/jenisproses/daftarJenisProses.vue"),
  },
  {
    path: "/jenis-proses/add",
    name: "jenis-proses-add",
    component: () =>
      import("@/views/admin/jenisproses/tambahUbahJenisProses.vue"),
  },
  {
    path: "/jenis-proses/edit/:id",
    name: "jenis-proses-ubah",
    component: () =>
      import("@/views/admin/jenisproses/tambahUbahJenisProses.vue"),
  },
  {
    path: "/jenis-proses/view/:id",
    name: "jenis-proses-detail",
    component: () =>
      import("@/views/admin/jenisproses/tambahUbahJenisProses.vue"),
  },

  // Tarif izin
  {
    path: "/tarif-izin",
    name: "tarif-izin",
    component: () => import("@/views/admin/tarif/daftarTarif.vue"),
  },
  {
    path: "/tarif-item/:id",
    name: "tarif-item",
    component: () => import("@/views/admin/tarif/ubahTarif.vue"),
  },
  {
    path: "/tarif-item/add/:id",
    name: "tarif-item-add",
    component: () => import("@/views/admin/tarif/formKategori.vue"),
  },
  {
    path: "/tarif-item/edit/:id/:keyid",
    name: "tarif-item-edit",
    component: () => import("@/views/admin/tarif/formKategori.vue"),
  },
  {
    path: "/tarif-item/view/:id/:keyid",
    name: "tarif-item-view",
    component: () => import("@/views/admin/tarif/formKategori.vue"),
  },

  // jenis dokumen
  {
    path: "/jenis-dokumen",
    name: "jenis-dokumen",
    component: () =>
      import("@/views/admin/jenisdokumen/daftarJenisDokumen.vue"),
  },

  // setting notifikasi
  {
    path: "/notifikasi",
    name: "notifikasi",
    component: () =>
      import("@/views/admin/settingnotifikasi/daftarSettingNotifikasi.vue"),
  },
  {
    path: "/notifikasi/:action/:id",
    name: "notifikasi-view-edit",
    component: () =>
      import("@/views/admin/settingnotifikasi/tambahUbahSettingNotifikasi.vue"),
  },

  // penomoran
  {
    path: "/penomoran",
    name: "penomoran",
    component: () => import("@/views/admin/penomoran/daftarPenomoran.vue"),
  },
  {
    path: "/penomoran/add",
    name: "penomoran-add",
    component: () =>
      import("@/views/admin/penomoran/tambahUbahDetailPenomoran.vue"),
  },
  {
    path: "penomoran/view/:id",
    name: "penomoran-detail",
    component: () => import("@/views/admin/penomoran/DetailPenomoran.vue"),
  },
  {
    path: "penomoran/edit/:id",
    name: "penomoran-edit",
    component: () =>
      import("@/views/admin/penomoran/tambahUbahDetailPenomoran.vue"),
  },

  // bidang usaha
  {
    path: "/bidang-usaha",
    name: "bidang-usaha",
    component: () => import("@/views/admin/bidangusaha/daftarBidangUsaha.vue"),
  },

  // jenis usaha
  {
    path: "/jenis-usaha",
    name: "jenis-usaha",
    component: () => import("@/views/admin/jenisusaha/daftarJenisUsaha.vue"),
  },

  // kalender
  {
    path: "/kalender",
    name: "admin-daftar-kalender",
    component: () => import("@/views/admin/kalender/daftarKalender.vue"),
  },
  {
    path: "/kalender/edit/:id",
    name: "admin-daftar-kalender-ubah",
    component: () => import("@/views/admin/kalender/ubahDaftarKalender.vue"),
  },

  // setting Video
  {
    path: "/video-tutorial",
    name: "video-tutorial",
    component: () => import("@/views/admin/videotutorial/settingVideo.vue"),
  },
  {
    path: "/video-tutorial/add",
    name: "video-tutorial-add",
    component: () => import("@/views/admin/videotutorial/formSettingVideo.vue"),
  },
  {
    path: "/video-tutorial/edit/:id",
    name: "video-tutorial-edit",
    component: () => import("@/views/admin/videotutorial/formSettingVideo.vue"),
  },
  {
    path: "/video-tutorial/show",
    name: "video-tutorial-show",
    component: () => import("@/views/admin/videotutorial/videoTutorial.vue"),
  },

  // report component
  {
    path: "/report-component",
    name: "admin-report-component",
    component: () =>
      import("@/views/admin/reportcomponent/reportComponent.vue"),
  },
  {
    path: "/report-component/ubah/:id",
    name: "admin-report-component-ubah",
    component: () =>
      import("@/views/admin/reportcomponent/ubahDetailReportComponent.vue"),
  },
  {
    path: "/report-component/detail/:id",
    name: "admin-report-component-detail",
    component: () =>
      import("@/views/admin/reportcomponent/ubahDetailReportComponent.vue"),
  },

  // notifikasi perizinan
  {
    path: "/admin/notifikasi-perizinan",
    name: "admin-notifikasi-perizinan",
    component: () =>
      import("@/views/admin/notifikasiperizinan/notifikasiPerizinan.vue"),
  },

  // template form
  {
    path: "/template-form",
    name: "template-form",
    component: () => import("@/views/admin/templateform/templateForm.vue"),
  },
  {
    path: "/template-form/add",
    name: "template-form-add",
    component: () => import("@/views/admin/templateform/templateFormAdd.vue"),
  },
  {
    path: "/template-form/edit/:id",
    name: "template-form-edit",
    component: () => import("@/views/admin/templateform/templateFormAdd.vue"),
  },
  {
    path: "/template-form/preview/:id",
    name: "template-form-view",
    component: () => import("@/views/admin/templateform/templateFormView.vue"),
  },

  // Registrasi Pemohon
  {
    path: "/pemohon-register",
    name: "pemohon-register",
    component: () => import("@/views/admin/pemohon-register/daftarPemohon.vue"),
  },
  {
    path: "/pemohon-register/edit/:id",
    name: "pemohon-register-edit",
    component: () => import("@/views/admin/pemohon-register/formPemohon.vue"),
  },
  {
    path: "/pemohon-register/view/:id",
    name: "pemohon-register-view",
    component: () => import("@/views/admin/pemohon-register/formPemohon.vue"),
  },

  // aduan kategori
  {
    path: "/aduan-kategori-list",
    name: "admin-daftar-aduan-kategori",
    component: () =>
      import("@/views/admin/aduankategori/daftarAduanKategori.vue"),
  },

  // Pegawai
  {
    path: "/pegawai",
    name: "admin-data-pegawai",
    component: () => import("@/views/admin/pegawai/Pegawai.vue"),
  },
  {
    path: "/pegawai/add",
    name: "admin-data-pegawai-add",
    component: () => import("@/views/admin/pegawai/components/AddPegawai.vue"),
  },
  {
    path: "/pegawai/edit/:id",
    name: "admin-data-pegawai-edit",
    component: () => import("@/views/admin/pegawai/components/AddPegawai.vue"),
  },

  // Struktur Instansi
  {
    path: "/unit",
    name: "admin-data-instansi",
    component: () => import("@/views/admin/instansi/StrukturInstansi.vue"),
  },

  // penangangan aduan
  {
    path: "/penanganan-aduan",
    name: "admin-daftar-aduan",
    component: () => import("@/views/admin/daftaraduan/DaftarAduan.vue"),
  },
  {
    path: "/penanganan-aduan/detail/:id",
    name: "admin-daftar-aduan/detail",
    component: () =>
      import("@/views/admin/daftaraduan/components/DetailAduan.vue"),
  },

  // pengaturan nomenklatur
  {
    path: "/admin/pengaturan-nomenklatur",
    name: "admin-pengaturan-nomenklatur",
    component: () =>
      import("@/views/admin/pengaturannomenklatur/PengaturanNomenklatur.vue"),
  },

  // kamus data
  {
    path: "/kamus-data",
    name: "admin-pengaturan-kamus",
    component: () => import("@/views/admin/daftarkamus/DaftarKamus.vue"),
  },
  {
    path: "/kamus-data/add",
    name: "admin-pengaturan-kamus-add",
    component: () =>
      import(
        "@/views/admin/daftarkamus/components/tambahUbahFom/formTambahKamus.vue"
      ),
  },
  {
    path: "/kamus-data/edit/:id",
    name: "admin-pengaturan-kamus-edit",
    component: () =>
      import(
        "@/views/admin/daftarkamus/components/tambahUbahFom/formTambahKamus.vue"
      ),
  },
  {
    path: "/kamus-data/detail/:id",
    name: "admin-pengaturan-kamus-detail",
    component: () =>
      import("@/views/admin/daftarkamus/components/DetailKamus.vue"),
  },

  // menu
  {
    path: "/menu",
    name: "admin-daftar-menu",
    component: () => import("@/views/admin/daftarmenu/StrukturMenu.vue"),
  },
  {
    path: "/menu/add",
    name: "admin-daftar-menu-add",
    component: () => import("@/views/admin/daftarmenu/components/AddMenu.vue"),
  },
  {
    path: "/menu/edit/:id",
    name: "admin-daftar-menu-edit",
    component: () => import("@/views/admin/daftarmenu/components/AddMenu.vue"),
  },

  // ---- Peran -----
  {
    path: "/peran",
    name: "admin-daftar-peran",
    component: () => import("@/views/admin/daftarperan/DaftarPeran.vue"),
  },
  {
    path: "/peran/add",
    name: "admin-daftar-peran-add",
    component: () =>
      import("@/views/admin/daftarperan/components/AddPeran.vue"),
  },
  {
    path: "/peran/edit/:id",
    name: "admin-daftar-peran-edit",
    component: () =>
      import("@/views/admin/daftarperan/components/AddPeran.vue"),
  },
  {
    path: "/peran/detail/:id",
    name: "admin-daftar-peran-detail",
    component: () =>
      import("@/views/admin/daftarperan/components/DetailPeran.vue"),
  },

  // ---- Pengguna -----
  {
    path: "/pengguna",
    name: "admin-daftar-pengguna",
    component: () => import("@/views/admin/daftar-pengguna/DaftarPengguna.vue"),
  },
  {
    path: "/pengguna/add",
    name: "admin-daftar-pengguna-add",
    component: () =>
      import(
        "@/views/admin/daftar-pengguna/components/tambahUbahForm/formTambahPengguna.vue"
      ),
  },
  {
    path: "/pengguna/edit/:id",
    name: "admin-daftar-pengguna-edit",
    component: () =>
      import(
        "@/views/admin/daftar-pengguna/components/tambahUbahForm/formTambahPengguna.vue"
      ),
  },
  {
    path: "/pengguna/detail/:id",
    name: "admin-daftar-pengguna-detail",
    component: () =>
      import("@/views/admin/daftar-pengguna/components/DetailPengguna.vue"),
  },

  // form assessment
  {
    path: "/form-assessment",
    name: "form-assessment",
    component: () => import("@/views/admin/daftarsurvey/DaftarSurvey.vue"),
  },
  {
    path: "/form-assessment/add",
    name: "form-assessment-add",
    component: () =>
      import(
        "@/views/admin/daftarsurvey/components/components/AddSurveySaya.vue"
      ),
  },
  {
    path: "/form-assessment/edit/:id",
    name: "form-assessment-edit",
    component: () =>
      import(
        "@/views/admin/daftarsurvey/components/components/AddSurveySaya.vue"
      ),
  },
  {
    path: "/form-assessment/detail-daerah/:params",
    name: "form-assessment-detail-daerah",
    component: () =>
      import("@/views/admin/daftarsurvey/components/SurveyDetailDaerah.vue"),
  },
  {
    path: "/form-assessment/view-survey/:id",
    name: "form-assessment-detail",
    component: () =>
      import(
        "@/views/admin/daftarsurvey/components/components/AddSurveySaya.vue"
      ),
  },
  {
    path: "/list-periode",
    name: "admin-daftar-survey-hasil",
    component: () => import("@/views/admin/daftarsurvey/hasil/DaftarHasil.vue"),
  },

  // daftar survey
  {
    path: "/form-assessment/view-permohonan-instansi/:id",
    name: "form-assessment-view-permohonan-instansi",
    component: () =>
      import(
        "@/views/admin/daftarsurvey/hasil/components/components/DataRatingLayanan.vue"
      ),
  },
  {
    path: "/form-assessment/view-permohonan-Admin/:id",
    name: "form-assessment-view-permohonan-admin",
    component: () =>
      import(
        "@/views/admin/daftarsurvey/hasil/components/components/DataRatingLayananAdmin.vue"
      ),
  },
  {
    path: "/form-assessment/view-responden/:id/:surveyid",
    name: "form-assessment-view-responden",
    component: () =>
      import(
        "@/views/admin/daftarsurvey/hasil/components/DetailSurveyResponden.vue"
      ),
  },
  {
    path: "/admin/daftar-survey/hasil/layanan-rating/detail",
    name: "admin-daftar-survey-hasil/layanan-rating/detail",
    component: () =>
      import(
        "@/views/admin/daftarsurvey/hasil/components/components/DetailRatingLayanan.vue"
      ),
  },

  // daftar permohonan izin ditolak
  {
    path: "/daftar-izin-ditolak",
    name: "daftar-izin-ditolak",
    component: () =>
      import("@/views/admin/permohonan-izin-ditolak/PermohonanDitolak.vue"),
  },
  {
    path: "/daftar-izin/view/:id",
    name: "daftar-izin-detail",
    component: () =>
      import(
        "@/views/admin/permohonan-izin-ditolak/components/DetailPermohonan.vue"
      ),
  },

  // permohonan izin
  {
    path: "/permohonan-izin",
    name: "permohonan-izin",
    component: () => import("@/views/admin/permohonan-izin/Permohonan.vue"),
  },
  {
    path: "permohonan-izin/view/:id",
    name: "permohonan-izin-detail",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/permohonan/detailPermohonan.vue"
      ),
  },
  {
    path: "permohonan-izin/edit/:id",
    name: "permohonan-izin-edit",
    component: () =>
      import(
        "@/views/admin/permohonan-izin/components/permohonan-edit/formEditPermohonan.vue"
      ),
  },
  {
    path: "/permohonan-izin/add",
    name: "permohonan-izin-add",
    component: () =>
      import(
        "@/views/admin/permohonan-izin/components/permohonan/formTambahPermohonan.vue"
      ),
  },

  // daftar izin
  {
    path: "daftar-izin",
    name: "daftar-izin",
    component: () => import("@/views/admin/daftarizin/daftarIzin.vue"),
  },

  // daftar pemohon
  {
    path: "/pemohon",
    name: "pemohon",
    component: () => import("@/views/admin/daftarpemohon/daftarPemohon.vue"),
  },
  {
    path: "/pemohon/edit/:id",
    name: "pemohon-edit",
    component: () => import("@/views/admin/daftarpemohon/formUbahPemohon.vue"),
  },
  {
    path: "/pemohon/view/:id",
    name: "pemohon-detail",
    component: () =>
      import("@/views/admin/daftarpemohon/detailpemohon/daftarPermohonan.vue"),
    children: [
      // permohonan
      {
        path: "/pemohon/view/permohonan/:id",
        name: "pemohon-detail-daftar-permohonan-permohonan",
        component: () =>
          import(
            "@/views/admin/daftarpemohon/detailpemohon/layoutpermohonan/permohonan.vue"
          ),
      },
      // perusahaan
      {
        path: "/pemohon/view/perusahaan/:id",
        name: "pemohon-detail-daftar-permohonan-perusahaan",
        component: () =>
          import(
            "@/views/admin/daftarpemohon/detailpemohon/layoutpermohonan/perusahaan.vue"
          ),
      },
      // perizinan
      {
        path: "/pemohon/view/perizinan/:id",
        name: "pemohon-detail-daftar-permohonan-perizinan",
        component: () =>
          import(
            "@/views/admin/daftarpemohon/detailpemohon/layoutpermohonan/perizinan.vue"
          ),
      },
      {
        path: "/pemohon/view/dokumen/:id",
        name: "pemohon-detail-daftar-permohonan-dokumen",
        component: () =>
          import(
            "@/views/admin/daftarpemohon/detailpemohon/layoutpermohonan/Dokumen.vue"
          ),
      },
    ],
  },

  // tambah pemohon - permohonan
  {
    path: "/pemohon/add/permohonan/:id",
    name: "pemohon-add-permohonan",
    component: () =>
      import(
        "@/views/admin/permohonan-izin/components/permohonan/formTambahPermohonan.vue"
      ),
  },

  {
    path: "/pemohon/detail/permohonan/:id",
    name: "pemohon-detail-permohonan",
    component: () =>
      import(
        "@/views/admin/daftarpemohon/detailpemohon/layoutpermohonan/layoutForm/permohonan/detailPermohonan.vue"
      ),
  },

  // form dinamsi list
  {
    path: "/form/list/:id",
    name: "form-list",
    component: () => import("@/views/admin/templateDynamic/listForm.vue"),
  },
  {
    path: "/form/render/:id",
    name: "form-render",
    component: () => import("@/views/admin/templateDynamic/renderForm.vue"),
  },
  {
    path: "/form/render/:id/:keyid",
    name: "form-render-id",
    component: () => import("@/views/admin/templateDynamic/renderForm.vue"),
  },
  {
    path: "/form/report/:id",
    name: "form-report",
    component: () => import("@/views/admin/templateDynamic/reportForm.vue"),
  },
  {
    path: "/form/report-grid/:id",
    name: "form-report-grid",
    component: () => import("@/views/admin/templateDynamic/reportGridForm.vue"),
  },

  // lapor aduan
  // {
  //   path: "/aduan",
  //   name: "lapor-aduan",
  //   component: () => import("@/views/admin/laporaduan/DaftarAduan.vue"),
  // },
  {
    path: "/aduan/view/:id",
    name: "lapor-aduan-view",
    component: () =>
      import("@/views/admin/laporaduan/components/DetailAduan.vue"),
  },

  // template data
  {
    path: "/template-data",
    name: "admin-template-data",
    component: () => import("@/views/admin/templatedata/templateData.vue"),
  },
  {
    path: "/template-data/add",
    name: "admin-template-data/add",
    component: () => import("@/views/admin/templatedata/templateDataAdd.vue"),
  },
  {
    path: "/template-data/edit/:id",
    name: "admin-template-data/ubah",
    component: () => import("@/views/admin/templatedata/templateDataAdd.vue"),
  },
  {
    path: "/template-data/detail/:id",
    name: "admin-template-data/detail",
    component: () =>
      import("@/views/admin/templatedata/templateDataDetail.vue"),
  },

  // aplikasi mobile
  {
    path: "/admin/aplikasi-mobile",
    name: "admin-aplikasi-mobile",
    component: () => import("@/views/admin/aplikasi-mobile/AplikasiMobile.vue"),
  },
  {
    path: "/admin/aplikasi-mobile/add",
    name: "admin-aplikasi-mobile-add",
    component: () =>
      import(
        "@/views/admin/aplikasi-mobile/components/AddEditAplikasiMobile.vue"
      ),
  },

  // unduh aplikasi
  {
    path: "/admin/unduh-aplikasi",
    name: "admin-unduh-aplikasi",
    component: () => import("@/views/admin/unduh-aplikasi/AplikasiMobile.vue"),
  },

  // proses NIB
  {
    path: "/proses-nib",
    name: "proses-nib",
    component: () => import("@/views/admin/prosesnib/ProsesNIB.vue"),
  },
  {
    path: "/proses-pengajuan/nib/:id",
    name: "proses-nib-view",
    component: () =>
      import("@/views/admin/prosesnib/components/ProsesPengajuan.vue"),
  },

  // setting grafik
  {
    path: "/dashboard-setting",
    name: "admin-setting-grafik",
    component: () => import("@/views/admin/setting-grafik/SettingGrafik.vue"),
  },

  // rest service
  {
    path: "rest-service",
    name: "rest-service",
    component: () => import("@/views/admin/rest-service/RestService.vue"),
  },

  // pengguna-rest
  {
    path: "/pengguna-rest",
    name: "admin-daftar-pengguna-rest",
    component: () =>
      import("@/views/admin/pengguna-rest/DaftarPenggunaRest.vue"),
  },

  // service eksternal
  {
    path: "/service-eksternal",
    name: "service-eksternal",
    component: () =>
      import("@/views/admin/service-eksternal/ServiceEksternal.vue"),
  },

  {
    path: "/service-eksternal/add",
    name: "service-eksternal-add",
    component: () =>
      import("@/views/admin/service-eksternal/components/AddService.vue"),
  },

  {
    path: "/service-eksternal/edit/:id",
    name: "service-eksternal-edit",
    component: () =>
      import("@/views/admin/service-eksternal/components/AddService.vue"),
  },

  {
    path: "/service-eksternal/detail/:id",
    name: "service-eksternal-detail",
    component: () =>
      import("@/views/admin/service-eksternal/components/AddService.vue"),
  },

  // pengguna sms
  {
    path: "/pengguna-gateway",
    name: "admin-daftar-pengguna-sms",
    component: () => import("@/views/admin/pengguna-sms/DaftarPenggunaSms.vue"),
  },

  // TTE
  {
    path: "/signature-tte",
    name: "signature-tte",
    component: () => import("@/views/admin/tte/daftarTTE.vue"),
  },
  {
    path: "/signature-tte/report/:id",
    name: "signature-report",
    component: () => import("@/views/admin/tte/signatureReportTTE.vue"),
  },
  {
    path: "/signature-tte/report/:id",
    name: "signature-report",
    component: () => import("@/views/admin/tte/signatureReportTTE.vue"),
  },

  // proses-permohonan
  {
    path: "/proses-pengajuan",
    name: "proses-pengajuan",
    component: () => import("@/views/admin/proses-permohonan/Permohonan.vue"),
  },
  {
    path: "/proses-pengajuan/view/:id",
    name: "proses-pengajuan-detail",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/permohonan/detailPermohonan.vue"
      ),
  },

  // kategori artikel
  {
    path: "/artikel-kategori",
    name: "artikel-kategori",
    component: () =>
      import("@/views/admin/kategori-artikel/daftarKategoriArtikel.vue"),
  },

  // artikel
  {
    path: "/artikel",
    name: "artikel",
    component: () => import("@/views/admin/artikel/daftarArtikel.vue"),
  },
  {
    path: "/artikel/add",
    name: "artikel-add",
    component: () => import("@/views/admin/artikel/tambahUbahArtikel.vue"),
  },
  {
    path: "/artikel/edit/:id",
    name: "artikel-ubah",
    component: () => import("@/views/admin/artikel/tambahUbahArtikel.vue"),
  },
  {
    path: "/artikel/view/:id",
    name: "artikel-detail",
    component: () => import("@/views/admin/artikel/tambahUbahArtikel.vue"),
  },

  // informasi kategori
  {
    path: "/informasi-kategori",
    name: "informasi-kategori",
    component: () =>
      import("@/views/admin/kategori-informasi/KategoriInformasi.vue"),
  },

  // informasi
  {
    path: "/informasi",
    name: "informasi",
    component: () => import("@/views/admin/informasi/index.vue"),
  },
  {
    path: "/informasi/add",
    name: "informasi-add",
    component: () => import("@/views/admin/informasi/add.vue"),
  },
  {
    path: "/informasi/edit/:id",
    name: "informasi-edit",
    component: () => import("@/views/admin/informasi/add.vue"),
  },
  {
    path: "/informasi/view/:id",
    name: "informasi-detail",
    component: () => import("@/views/admin/informasi/detail.vue"),
  },

  // Galeri
  {
    path: "/manajemen-galeri",
    name: "manajemen-galeri",
    component: () => import("@/views/admin/manajemen-galeri/index.vue"),
  },
  {
    path: "/manajemen-galeri/add",
    name: "manajemen-galeri-add",
    component: () => import("@/views/admin/manajemen-galeri/add.vue"),
  },
  {
    path: "/manajemen-galeri/edit/:id",
    name: "manajemen-galeri-edit",
    component: () => import("@/views/admin/manajemen-galeri/add.vue"),
  },
  {
    path: "/manajemen-galeri/view/:id",
    name: "manajemen-galeri-detail",
    component: () => import("@/views/admin/manajemen-galeri/detail.vue"),
  },

  // Prosedur Operasional
  {
    path: "/prosedur-operasional",
    name: "prosedur",
    component: () => import("@/views/admin/prosedur-operasional/index.vue"),
  },
  {
    path: "/prosedur-operasional/add",
    name: "prosedur-add",
    component: () => import("@/views/admin/prosedur-operasional/add.vue"),
  },
  {
    path: "/prosedur-operasional/edit/:id",
    name: "prosedur-edit",
    component: () => import("@/views/admin/prosedur-operasional/add.vue"),
  },
  {
    path: "/prosedur-operasional/view/:id",
    name: "prosedur-detail",
    component: () => import("@/views/admin/prosedur-operasional/detail.vue"),
  },
  {
    path: "/retribusi/add/:permohonanid/:prosespermohoananid",
    name: "retribusi-add",
    component: () => import("@/views/pemohon/simulasitarif/SimulasiTarif.vue"),
  },
  // form-add
  {
    path: "/form/add/:id/:permohonanid/:prosespermohoananid",
    name: "form-add",
    component: () => import("@/views/admin/templateDynamic/formAdd.vue"),
  },
  // Simulasi tarif
  {
    path: "/simulasi-tarif",
    name: "simulasi-tarif",
    component: () => import("@/views/pemohon/simulasitarif/SimulasiTarif.vue"),
  },
  // profile pengguna Admin
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/pemohon/profile/index.vue"),
  },
  // profile pemohon
  {
    path: "/profile/pemohon",
    name: "pemohon-daftar-permohonan",
    component: () =>
      import("@/views/pemohon/daftarpermohonan/daftarPermohonan.vue"),
    children: [
      // permohonan
      {
        path: "/profile/pemohon/daftar-permohonan/permohonan",
        name: "pemohon-daftar-permohonan-permohonan",
        component: () =>
          import(
            "@/views/pemohon/daftarpermohonan/layoutpermohonan/permohonan.vue"
          ),
      },
      // perusahaan
      {
        path: "/profile/pemohon/daftar-permohonan/perusahaan",
        name: "pemohon-daftar-permohonan-perusahaan",
        component: () =>
          import(
            "@/views/pemohon/daftarpermohonan/layoutpermohonan/perusahaan.vue"
          ),
      },
      // perizinan
      {
        path: "/profile/pemohon/daftar-permohonan/perizinan",
        name: "pemohon-daftar-permohonan-perizinan",
        component: () =>
          import(
            "@/views/pemohon/daftarpermohonan/layoutpermohonan/perizinan.vue"
          ),
      },
      {
        path: "/profile/pemohon/daftar-permohonan/dokumen",
        name: "pemohon-daftar-permohonan-dokumen",
        component: () =>
          import(
            "@/views/pemohon/daftarpermohonan/layoutpermohonan/Dokumen.vue"
          ),
      },
    ],
  },
  // Add Pemohon
  {
    path: "/profile/pemohon/daftar-permohonan/add/permohonan",
    name: "pemohon-daftar-permohonan-add-permohonan",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/permohonan/formTambahPermohonan.vue"
      ),
  },
  {
    path: "/profile/pemohon/permohonan-izin-detail/:id",
    name: "pemohon-daftar-permohonan-detail-permohonan",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/permohonan/detailPermohonan.vue"
      ),
  },
  {
    path: "/profile/pemohon/daftar-permohonan/edit/permohonan/:id",
    name: "pemohon-daftar-permohonan-edit-permohonan",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/permohonan-edit/formEditPermohonan.vue"
      ),
  },
  {
    path: "/faq-category",
    name: "faq-category",
    component: () => import("@/views/admin/kategori-faq/daftarKategoriFAQ.vue"),
  },

  {
    path: "/faq-setting",
    name: "faq-setting",
    component: () => import("@/views/admin/faq-setting/daftarFAQSetting.vue"),
  },
  {
    path: "/faq-setting/add",
    name: "faq-setting-add",
    component: () => import("@/views/admin/faq-setting/tambahUbahFAQ.vue"),
  },
  {
    path: "/faq-setting/edit/:id",
    name: "faq-setting-ubah",
    component: () => import("@/views/admin/faq-setting/tambahUbahFAQ.vue"),
  },
  {
    path: "/faq-setting/view/:id",
    name: "faq-setting-detail",
    component: () => import("@/views/admin/faq-setting/tambahUbahFAQ.vue"),
  },
  {
    path: "/slider",
    name: "slider",
    component: () => import("@/views/admin/cms-slider/CmsSlider.vue"),
  },
  {
    path: "/jenis-izin-kategori/add",
    name: "jenis-izin-kategori-add",
    component: () => import("@/views/admin/jenis-izin-kategori/form.vue"),
  },
  {
    path: "/jenis-izin-kategori-edit/:id",
    name: "jenis-izin-kategori-edit",
    component: () => import("@/views/admin/jenis-izin-kategori/form.vue"),
  },
  {
    path: "/jenis-izin-kategori-view/:id",
    name: "jenis-izin-kategori-view",
    component: () => import("@/views/admin/jenis-izin-kategori/form.vue"),
  },
  {
    path: "/jenis-izin-kategori",
    name: "jenis-izin-kategori",
    component: () => import("@/views/admin/jenis-izin-kategori/index.vue"),
  },
  {
    path: "/integrasi-kki",
    name: "integrasi-kki",
    component: () => import("@/views/admin/integrasi-kki/index.vue"),
  },
  {
    path: "/peta-sebaran",
    name: "peta-sebaran",
    component: () => import("@/views/admin/petasebaran/petasebaran.vue"),
  },
  {
    path: "/dashboardkpk",
    name: "dashboardkpk",
    component: () => import("@/views/admin/dashboardkpk/dashboard.vue"),
  },

  // service eksternal
  {
    path: "/wse-splp",
    name: "wse-splp",
    component: () =>
      import("@/views/admin/service-eksternal/WSESPLP/DaftarWseSplp.vue"),
  },

  {
    path: "/wse-splp/detail/:id",
    name: "wse-splp-detail",
    component: () =>
      import("@/views/admin/service-eksternal/WSESPLP/DetailWseSPLP.vue"),
  },

  {
    path: "/wse-splp-detail-intansi/detail/:instansi/:id",
    name: "wse-splp-detail-intansi",
    component: () =>
      import(
        "@/views/admin/service-eksternal/WSESPLP/components/AddService.vue"
      ),
  },

  // log tte
  {
    path: "/log-tte",
    name: "log-tte",
    component: () => import("@/views/admin/log-tte/log-tte.vue"),
  },

  // log Send Email
  {
    path: "/log-sender-email",
    name: "log-sender-email",
    component: () =>
      import("@/views/admin/log-sender-mail/daftar-log-sender.vue"),
  },

  // log SMS
  {
    path: "/log-sms",
    name: "log-sms",
    component: () => import("@/views/admin/log-sms/log-sms.vue"),
  },

  // log SMS
  {
    path: "/data-kesehatan",
    name: "data-kesehatan",
    component: () => import("@/views/admin/data-kesehatan/data-kesehatan.vue"),
  },

  // log sisdmk
  {
    path: "/log-data-kesehatan",
    name: "log-data-kesehatan",
    component: () => import("@/views/admin/log-sisdmk/log-sisdmk.vue"),
  },

  // log import permohonan
  {
    path: "/log-import-permohonan",
    name: "log-import-permohonan",
    component: () =>
      import("@/views/admin/log-import-permohonan/logImportPermohonan.vue"),
  },

  // dashboard-data kesehatan
  {
    path: "/dashboard-data-kesehatan",
    name: "dashboard-data-kesehatan",
    component: () =>
      import("@/views/admin/dashboard-data-kesehatan/dashboard.vue"),
  },

  {
    path: "/dashboard-data-kesehatan/:module",
    name: "dashboard-data-kesehatan-report",
    component: () =>
      import(
        "@/views/admin/dashboard-data-kesehatan/report-redirect/dashboardDetail.vue"
      ),
  },
];
